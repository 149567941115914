import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
const SeoComp = ({
  title,
  description,
  image,
  article,
  intl,
  twitter_card,
  jsonLd,
  productType,
  productPrice,
  hreflangPathname,
  noindex,
  nofollow,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    // titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }
  var content = `${noindex ? "noindex" : "index"}, ${
    nofollow ? "nofollow" : "follow"
  }`

  const currencyCode = process.env.GATSBY_ACTIVE_ENV.toUpperCase()
  // {intl.formatMessage({ id: "out_of_stock_message" })}
  let price_129_eur = "129"
  let price_129_eur_pack = "258"
  let discount_price_129_eur_pack = "258"
  switch (currencyCode) {
    case "EUR":
      price_129_eur = "129"
      price_129_eur_pack = "258"
      discount_price_129_eur_pack = "206.4"
      break
    case "GBP":
      price_129_eur = "84"
      price_129_eur_pack = "198"
      discount_price_129_eur_pack = "173.25"
      break
    case "USD":
      price_129_eur = "149"
      price_129_eur_pack = "298"
      discount_price_129_eur_pack = "238.40"
      break
    case "CAD":
      price_129_eur = "189"
      price_129_eur_pack = "378"
      discount_price_129_eur_pack = "302.40"
      break
  }

  let price_54_eur = "54"
  switch (currencyCode) {
    case "EUR":
      price_54_eur = "29"
      break
    case "GBP":
      price_54_eur = "25"
      break
    case "USD":
      price_54_eur = "30"
      break
    case "CAD":
      price_54_eur = "39"
      break
  }

  let price_79_eur = "79"

  return (
    <Helmet
      title={seo.title}
      //   titleTemplate={titleTemplate}
    >
      {process.env.GATSBY_WEBSITE_URL === "www.percko.com" &&
        (noindex || nofollow) && <meta name="robots" content={content} />}
      {process.env.GATSBY_WEBSITE_URL === "www.percko.com" &&
        (noindex || nofollow) && <meta name="googlebot" content={content} />}
      {process.env.GATSBY_WEBSITE_URL != "www.percko.com" && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {process.env.GATSBY_WEBSITE_URL != "www.percko.com" && (
        <meta name="googlebot" content="noindex, nofollow" />
      )}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:type" content="website" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:site_name" content="PERCKO" />
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content={twitter_card} />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {/* hrefLang Items */}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "eur" && (
        <link
          rel="canonical"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/${
            intl.locale === "fr" ? "" : intl.locale + "/"
          }${hreflangPathname[intl.locale]}${
            hreflangPathname[intl.locale] ? "/" : ""
          }`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "eur" && (
        <link
          rel="alternate"
          hreflang="en-US"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/usd/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "eur" && (
        <link
          rel="alternate"
          hreflang="en-GB"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/gbp/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "eur" && (
        <link
          rel="alternate"
          hreflang="en-CA"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "eur" && (
        <link
          rel="alternate"
          hreflang="fr-CA"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/fr/${
            hreflangPathname.fr
          }${hreflangPathname.fr ? "/" : ""}`}
        />
      )}
      {hreflangPathname &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        intl.locale !== "fr" &&
        intl.locale !== "" && (
          <link
            rel="alternate"
            hreflang="fr-FR"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/${
              hreflangPathname.fr
            }${hreflangPathname.fr ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        intl.locale !== "en" && (
          <link
            rel="alternate"
            hreflang="en-IE"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/en/${
              hreflangPathname.en
            }${hreflangPathname.en ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        intl.locale !== "de" && (
          <link
            rel="alternate"
            hreflang="de-DE"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/de/${
              hreflangPathname.de
            }${hreflangPathname.de ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        intl.locale !== "es" && (
          <link
            rel="alternate"
            hreflang="es-ES"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/es/${
              hreflangPathname.es
            }${hreflangPathname.es ? "/" : ""}`}
          />
        )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "usd" && (
        <link
          rel="canonical"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/usd/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "usd" && (
        <link
          rel="alternate"
          hreflang="fr-FR"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/${
            hreflangPathname.fr
          }${hreflangPathname.fr ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "usd" && (
        <link
          rel="alternate"
          hreflang="en-GB"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/gbp/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "usd" && (
        <link
          rel="alternate"
          hreflang="en-IE"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/en/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "usd" && (
        <link
          rel="alternate"
          hreflang="en-CA"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "usd" && (
        <link
          rel="alternate"
          hreflang="fr-CA"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/fr/${
            hreflangPathname.fr
          }${hreflangPathname.fr ? "/" : ""}`}
        />
      )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "usd" && (
          <link
            rel="alternate"
            hreflang="de-DE"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/de/${
              hreflangPathname.de
            }${hreflangPathname.de ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "usd" && (
          <link
            rel="alternate"
            hreflang="es-ES"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/es/${
              hreflangPathname.es
            }${hreflangPathname.es ? "/" : ""}`}
          />
        )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "gbp" && (
        <link
          rel="canonical"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/gbp/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "gbp" && (
        <link
          rel="alternate"
          hreflang="en-US"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/usd/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "gbp" && (
        <link
          rel="alternate"
          hreflang="fr-FR"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/${
            hreflangPathname.fr
          }${hreflangPathname.fr ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "gbp" && (
        <link
          rel="alternate"
          hreflang="en-IE"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/en/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "gbp" && (
        <link
          rel="alternate"
          hreflang="en-CA"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "gbp" && (
        <link
          rel="alternate"
          hreflang="fr-CA"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/fr/${
            hreflangPathname.fr
          }${hreflangPathname.fr ? "/" : ""}`}
        />
      )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "gbp" && (
          <link
            rel="alternate"
            hreflang="de-DE"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/de/${
              hreflangPathname.de
            }${hreflangPathname.de ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "gbp" && (
          <link
            rel="alternate"
            hreflang="es-ES"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/es/${
              hreflangPathname.es
            }${hreflangPathname.es ? "/" : ""}`}
          />
        )}
      ) : (
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "cad" && (
        <link
          rel="canonical"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/${
            intl.locale === "en" ? "" : intl.locale + "/"
          }${hreflangPathname[intl.locale]}${
            hreflangPathname[intl.locale] ? "/" : ""
          }`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "cad" && (
        <link
          rel="alternate"
          hreflang="en-US"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/usd/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "cad" && (
        <link
          rel="alternate"
          hreflang="en-GB"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/gbp/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "cad" && (
        <link
          rel="alternate"
          hreflang="en-IE"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/en/${
            hreflangPathname.en
          }${hreflangPathname.en ? "/" : ""}`}
        />
      )}
      {hreflangPathname && process.env.GATSBY_ACTIVE_ENV === "cad" && (
        <link
          rel="alternate"
          hreflang="fr-FR"
          href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/${
            hreflangPathname.fr
          }${hreflangPathname.fr ? "/" : ""}`}
        />
      )}
      {hreflangPathname &&
        process.env.GATSBY_ACTIVE_ENV === "cad" &&
        intl.locale !== "en" &&
        intl.locale !== "" && (
          <link
            rel="alternate"
            hreflang="en-CA"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/${
              hreflangPathname.en
            }${hreflangPathname.en ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        process.env.GATSBY_ACTIVE_ENV === "cad" &&
        intl.locale !== "fr" && (
          <link
            rel="alternate"
            hreflang="fr-CA"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/cad/fr/${
              hreflangPathname.fr
            }${hreflangPathname.fr ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "cad" && (
          <link
            rel="alternate"
            hreflang="de-DE"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/de/${
              hreflangPathname.de
            }${hreflangPathname.de ? "/" : ""}`}
          />
        )}
      {hreflangPathname &&
        !hreflangPathname["fr"].includes("loop") &&
        process.env.GATSBY_ACTIVE_ENV === "cad" && (
          <link
            rel="alternate"
            hreflang="es-ES"
            href={`https://${process.env.GATSBY_WEBSITE_URL}/eur/es/${
              hreflangPathname.es
            }${hreflangPathname.es ? "/" : ""}`}
          />
        )}
      {jsonLd &&
        (jsonLd === "wearable-technology" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id:
                productType === "lynehome"
                  ? "seo_name_lynehome_product"
                  : productType === "lynefit"
                  ? "seo_name_lynefit_product"
                  : "seo_name_lyneup_product",
            })}",
            "image": "${
              productType === "lynehome"
                ? "https://static.percko.com/uploads/52cee70f-f294-4b33-bf17-02816464c89d.jpg"
                : productType === "lynefit"
                ? "https://static.percko.com/uploads/7eae79ba-2e0a-470e-9e81-d86b876da118.jpg"
                : "https://static.percko.com/uploads/25166551-4bb5-447b-a190-b18c92c6228a.jpg"
            }",
            "description": "${intl.formatMessage({
              id:
                productType === "lynehome"
                  ? "seo_description_lynehome_product"
                  : productType === "lynefit"
                  ? "seo_description_lynefit_product"
                  : "seo_description_lyneup_product",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "${
              productType === "lynehome"
                ? "FR-LYNE-HOME"
                : productType === "lynefit"
                ? "FR-LYNE-FIT"
                : "FR-LYNE-UP"
            }",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles/",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles/?Gender=Female&Offer=Seul&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles/?Gender=Male&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles/?Gender=Female&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles/?Gender=Male&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "wearable-technology-last-chance" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id:
                productType === "lynehome"
                  ? "seo_name_lynehome_product"
                  : productType === "lynefit"
                  ? "seo_name_lynefit_product"
                  : "seo_name_lyneup_product",
            })}",
            "image": "${
              productType === "lynehome"
                ? "https://static.percko.com/uploads/52cee70f-f294-4b33-bf17-02816464c89d.jpg"
                : productType === "lynefit"
                ? "https://static.percko.com/uploads/7eae79ba-2e0a-470e-9e81-d86b876da118.jpg"
                : "https://static.percko.com/uploads/25166551-4bb5-447b-a190-b18c92c6228a.jpg"
            }",
            "description": "${intl.formatMessage({
              id:
                productType === "lynehome"
                  ? "seo_description_lynehome_product"
                  : productType === "lynefit"
                  ? "seo_description_lynefit_product"
                  : "seo_description_lyneup_product",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "${
              productType === "lynehome"
                ? "FR-LYNE-HOME"
                : productType === "lynefit"
                ? "FR-LYNE-FIT"
                : "FR-LYNE-UP"
            }",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles-derniere-chance/",
                "priceCurrency": "${currencyCode}",
                "price": "129",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles-derniere-chance/?Gender=Female&Offer=Seul&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }",
                "priceCurrency": "${currencyCode}",
                "price": "129",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles-derniere-chance/?Gender=Male&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "129",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles-derniere-chance/?Gender=Female&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "206.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/solutions-textiles-derniere-chance/?Gender=Male&Type=${
              productType === "lynehome"
                ? "Gilet"
                : productType === "lynefit"
                ? "T-shirt-sport"
                : "Sous-vetement"
            }&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "206.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "lyneup" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({ id: "seo_name_lyneup" })}",
            "image": "https://static.percko.com/uploads/391d2eb0-468a-49a1-9d8d-7c98070a58b5.png",
            "description": "${intl.formatMessage({
              id: "seo_description_lyneup",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-LYNE-UP",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lyneup/",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lyneup/?Gender=Female&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lyneup/?Gender=Male&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lyneup/?Gender=Female&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lyneup/?Gender=Male&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "lynefit" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "${intl.formatMessage({ id: "seo_name_lynefit" })}",
            "image": "https://static.percko.com/uploads/fab644a3-ff38-43f3-bd2f-98251840995b.png",
            "description": "${intl.formatMessage({
              id: "seo_description_lynefit",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-LYNE-FIT",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynefit/",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynefit/?Gender=Female&Color=BLACK%20MICRO-PERFORATED&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynefit/?Gender=Male&Color=BLACK%20MICRO-PERFORATED&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynefit/?Gender=Female&Color=BLACK%20MICRO-PERFORATED&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynefit/?Gender=Male&Color=BLACK%20MICRO-PERFORATED&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "lynehome" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "${intl.formatMessage({ id: "seo_name_lynehome" })}",
            "image": "https://static.percko.com/uploads/3400ecbe-2be6-4139-bf78-2ea119506aa3.png",
            "description": "${intl.formatMessage({
              id: "seo_description_lynehome",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-LYNE-HOME",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynehome/",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynehome/?Gender=Female&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynehome/?Gender=Male&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_129_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynehome/?Gender=Female&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lynehome/?Gender=Male&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "${discount_price_129_eur_pack}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "sommier-original" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id: "seo_name_sommier_zone_original",
            })}",
            "image": "https://static.percko.com/uploads/2843c5b3-2ed7-4190-8c55-2f2be139bcbb.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_sommier_zone_original",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-SOMMIER-ORIGINAL",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/",
                "priceCurrency": "${currencyCode}",
                "price": "499",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=90x190cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "499",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=140x190cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "599",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=160x200cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "699",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=180x200cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "799",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=90x190cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "998.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=140x190cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "1334.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=160x200cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "1662.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-original/?Size=180x200cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "1838.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "sommier-coffre" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id: "seo_name_sommier_zone_coffre",
            })}",
            "image": "https://static.percko.com/uploads/3ad465a8-8a60-48d8-9de1-e13c1eba36be.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_sommier_zone_coffre",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-SOMMIER-COFFRE",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-coffre/",
                "priceCurrency": "${currencyCode}",
                "price": "1099",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-coffre/?Size=140x190cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "1734.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-coffre/?Size=160x200cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "2062.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-coffre/?Size=140x190cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "1099",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-coffre/?Size=160x200cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "1199",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "sommier-bois" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id: "seo_name_sommier_zone_bois_massif",
            })}",
            "image": "https://static.percko.com/uploads/3d28006e-4b6b-43d1-a17d-151974e8633d.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_sommier_zone_bois_massif",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-SOMMIER-BOIS-MASSIF",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-bois-massif/",
                "priceCurrency": "${currencyCode}",
                "price": "1099",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-bois-massif/?Size=140x190cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "1734.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-bois-massif/?Size=160x200cm&Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "2062.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-bois-massif/?Size=140x190cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "1099",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/sommier-zone-bois-massif/?Size=160x200cm&Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "1199",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "sommier-electrique" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id: "seo_name_sommier_electrique",
            })}",
            "image": "https://static.percko.com/uploads/e5f40b22-d33b-4ce9-bac5-d26e95256dd8.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_sommier_electrique",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-SOMMIER-ELECTRIQUE",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lit-electrique/",
                "priceCurrency": "${currencyCode}",
                "price": "1499.00",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lit-electrique/?Size=80x200cm",
                "priceCurrency": "${currencyCode}",
                "price": "1499.00",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lit-electrique/?Size=90x200cm",
                "priceCurrency": "${currencyCode}",
                "price": "1599",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lit-electrique/?Size=160x200cm",
                "priceCurrency": "${currencyCode}",
                "price": "2499",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/lit-electrique/?Size=180x200cm",
                "priceCurrency": "${currencyCode}",
                "price": "2599",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "matelas" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id:
                productType === "mousse"
                  ? "seo_name_matelas_mousse"
                  : "seo_name_matelas",
            })}",
            "image": "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg",
            "description": "${intl.formatMessage({
              id:
                productType === "mousse"
                  ? "seo_description_matelas_mousse"
                  : "seo_description_matelas",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "${
              productType === "mousse"
                ? "FR-NUIT-MATELAS-MOUSSE"
                : "FR-NUIT-MATELAS"
            }",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/",
                "priceCurrency": "${currencyCode}",
                "price": "594.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=80x200cm&Type=${productType}&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "424.15" : "594.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=90x190cm&Type=${productType}&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "466.65" : "636.65"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=90x200cm&Type=${productType}&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "483.65" : "679.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=140x190cm&Type=${productType}&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "636.65" : "908.65"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=140x200cm&Type=${productType}&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "679.65" : "993.65"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=160x200cm&Type=${productType}&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "806.65" : "1172.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=180x200cm&Type=${productType}&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "891.65" : "1274.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=80x200cm&Type=${productType}&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "462.40" : "622.40"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=90x190cm&Type=${productType}&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "502.40" : "662.40"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=90x200cm&Type=${productType}&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "518.40" : "702.40"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=140x190cm&Type=${productType}&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "725.60" : "981.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=140x200cm&Type=${productType}&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "765.60" : "1061.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=160x200cm&Type=${productType}&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "885.60" : "1229.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas/?Size=180x200cm&Type=${productType}&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "965.60" : "1325.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "matelas-hybride" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id: "seo_name_matelas",
            })}",
            "image": "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_matelas",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-MATELAS",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/",
                "priceCurrency": "${currencyCode}",
                "price": "594.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=80x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "594.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=90x190cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "636.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=90x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "679.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=140x190cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "908.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=140x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "993.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=160x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "1172.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=160x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "1274.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=80x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "622.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=90x190cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "662.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=90x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "702.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=140x190cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "981.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=140x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "1061.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=160x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "1229.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-hybride-ressorts-ensaches/?Size=180x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "1325.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "matelas-mousse" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id: "seo_name_matelas_mousse",
            })}",
            "image": "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_matelas_mousse",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-MATELAS-MOUSSE",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/",
                "priceCurrency": "${currencyCode}",
                "price": "424.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=80x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "424.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=90x190cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "466.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=90x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "483.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=140x190cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "636.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=140x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "679.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=160x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "806.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=180x200cm&Seul=1",
                "priceCurrency": "${currencyCode}",
                "price": "891.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=80x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "462.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=90x190cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "502.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=90x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "518.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=140x190cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "725.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=140x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "765.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=160x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "885.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-mousse-memoire-de-forme/?Size=180x200cm&Pack=1",
                "priceCurrency": "${currencyCode}",
                "price": "965.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "matelas-old" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id:
                productType === "mousse"
                  ? "seo_name_matelas_mousse"
                  : "seo_name_matelas",
            })}",
            "image": "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg",
            "description": "${intl.formatMessage({
              id:
                productType === "mousse"
                  ? "seo_description_matelas_mousse"
                  : "seo_description_matelas",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "${
              productType === "mousse"
                ? "FR-NUIT-MATELAS-MOUSSE"
                : "FR-NUIT-MATELAS"
            }",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/",
                "priceCurrency": "${currencyCode}",
                "price": "594.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=80x200cm&Offer=Seul&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "424.15" : "594.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=90x190cm&Offer=Seul&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "466.65" : "636.65"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=90x200cm&Offer=Seul&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "483.65" : "679.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=140x190cm&Offer=Seul&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "636.65" : "908.65"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=140x200cm&Offer=Seul&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "679.65" : "993.65"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=160x200cm&Offer=Seul&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "806.65" : "1172.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=180x200cm&Offer=Seul&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "891.65" : "1274.15"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=80x200cm&Offer=Pack&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "462.40" : "622.40"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=90x190cm&Offer=Pack&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "502.40" : "662.40"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=90x200cm&Offer=Pack&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "518.40" : "702.40"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=140x190cm&Offer=Pack&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "725.60" : "981.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=140x200cm&Offer=Pack&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "765.60" : "1061.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=160x200cm&Offer=Pack&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "885.60" : "1229.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-old/?Size=180x200cm&Offer=Pack&Type=${productType}",
                "priceCurrency": "${currencyCode}",
                "price": "${productType === "mousse" ? "965.60" : "1325.60"}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "matelas-new" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({
              id:
                productType === "mousse"
                  ? "seo_name_matelas_mousse"
                  : "seo_name_matelas",
            })}",
            "image": "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg",
            "description": "${intl.formatMessage({
              id:
                productType === "mousse"
                  ? "seo_description_matelas_mousse"
                  : "seo_description_matelas",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "${
              productType === "mousse"
                ? "FR-NUIT-MATELAS-MOUSSE"
                : "FR-NUIT-MATELAS"
            }",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/",
                "priceCurrency": "${currencyCode}",
                "price": "594.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=80x200cm&Matelas_hybride=1",
                "priceCurrency": "${currencyCode}",
                "price": "594.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x190cm&Matelas_hybride=1",
                "priceCurrency": "${currencyCode}",
                "price": "636.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x200cm&Matelas_hybride=1",
                "priceCurrency": "${currencyCode}",
                "price": "679.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x190cm&Matelas_hybride=1",
                "priceCurrency": "${currencyCode}",
                "price": "908.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x200cm&Matelas_hybride=1",
                "priceCurrency": "${currencyCode}",
                "price": "824.25",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=160x200cm&Matelas_hybride=1",
                "priceCurrency": "${currencyCode}",
                "price": "1172.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=180x200cm&Matelas_hybride=1",
                "priceCurrency": "${currencyCode}",
                "price": "1274.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=80x200cm&Matelas_hybride=1&Oreiller_universel=1",
                "priceCurrency": "${currencyCode}",
                "price": "622.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x190cm&Matelas_hybride=1&Oreiller_universel=1",
                "priceCurrency": "${currencyCode}",
                "price": "662.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x200cm&Matelas_hybride=1&Oreiller_universel=1",
                "priceCurrency": "${currencyCode}",
                "price": "702.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x190cm&Matelas_hybride=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "981.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x200cm&Matelas_hybride=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "1061.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=160x200cm&Matelas_hybride=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "1229.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=180x200cm&Matelas_hybride=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "1325.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=80x200cm&Matelas_mousse=1",
                "priceCurrency": "${currencyCode}",
                "price": "424.15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x190cm&Matelas_mousse=1",
                "priceCurrency": "${currencyCode}",
                "price": "466.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x200cm&Matelas_mousse=1",
                "priceCurrency": "${currencyCode}",
                "price": "483.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x190cm&Matelas_mousse=1",
                "priceCurrency": "${currencyCode}",
                "price": "636.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x200cm&Matelas_mousse=1",
                "priceCurrency": "${currencyCode}",
                "price": "679.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=160x200cm&Matelas_mousse=1",
                "priceCurrency": "${currencyCode}",
                "price": "806.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=180x200cm&Matelas_mousse=1",
                "priceCurrency": "${currencyCode}",
                "price": "891.65",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=80x200cm&Matelas_mousse=1&Oreiller_universel=1",
                "priceCurrency": "${currencyCode}",
                "price": "462.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x190cm&Matelas_mousse=1&Oreiller_universel=1",
                "priceCurrency": "${currencyCode}",
                "price": "502.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=90x200cm&Matelas_mousse=1&Oreiller_universel=1",
                "priceCurrency": "${currencyCode}",
                "price": "518.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x190cm&Matelas_mousse=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "725.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=140x200cm&Matelas_mousse=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "765.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=160x200cm&Matelas_mousse=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "885.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/matelas-new/?Size=180x200cm&Matelas_mousse=1&Oreiller_universel=2",
                "priceCurrency": "${currencyCode}",
                "price": "965.60",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "oreiller" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({ id: "seo_name_oreiller" })}",
            "image": "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_oreiller",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-OREILLER",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/oreiller/",
                "priceCurrency": "${currencyCode}",
                "price": "${price_79_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/oreiller/?Offer=Seul",
                "priceCurrency": "${currencyCode}",
                "price": "${price_79_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/oreiller/?Offer=Pack",
                "priceCurrency": "${currencyCode}",
                "price": "126.40",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "oreillers-ergonomiques" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({ id: "seo_name_oreiller_new" })}",
            "image": "https://static.percko.com/uploads/f3c48b63-0e64-44c5-aef8-827dddbd26d8.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_oreiller_new",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-OREILLER",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/oreillers-ergonomiques/",
                "priceCurrency": "${currencyCode}",
                "price": "${price_79_eur}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "chaise-bureau" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({ id: "seo_name_chaise_bureau" })}",
            "image": "https://static.percko.com/uploads/0f3a7466-f3c4-4521-83d3-0ea188536ded.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_chaise_bureau",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-CHAISE",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/chaise-bureau-ergonomique/",
                "priceCurrency": "${currencyCode}",
                "price": "509",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "chaise-gaming" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({ id: "seo_name_chaise_gaming" })}",
            "image": "https://static.percko.com/uploads/727854c6-4cff-4f9d-bdd8-e77815c818bd.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_chaise_gaming",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-CHAISE",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/chaise-gaming/",
                "priceCurrency": "${currencyCode}",
                "price": "509",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "surmatelas" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({ id: "seo_name_surmatelas" })}",
            "image": "https://static.percko.com/uploads/16dc0767-9005-42c3-9cc3-517cb0c1cacf.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_surmatelas",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-NUIT-SURMATELAS",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/surmatelas/",
                "priceCurrency": "${currencyCode}",
                "price": "233.10",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/surmatelas/?Size=90x200cm",
                "priceCurrency": "${currencyCode}",
                "price": "233.10",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/surmatelas/?Size=140x190cm",
                "priceCurrency": "${currencyCode}",
                "price": "323.10",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/surmatelas/?Size=160x200cm",
                "priceCurrency": "${currencyCode}",
                "price": "359.10",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/shop/surmatelas/?Size=180x200cm",
                "priceCurrency": "${currencyCode}",
                "price": "413.10",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : jsonLd === "e-carte-cadeau" ? (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":  "${intl.formatMessage({ id: "seo_name_e_carte_cadeau" })}",
            "image": "https://static.percko.com/uploads/8bdfe23d-89fe-4a9f-8fbb-3e84d5fb0371.png",
            "description": "${intl.formatMessage({
              id: "seo_description_e_carte_cadeau",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-GIFT-CARD-EUR",
            "offers": [
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/e-carte-cadeau/",
                "priceCurrency": "${currencyCode}",
                "price": "15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/e-carte-cadeau/?Montant=15",
                "priceCurrency": "${currencyCode}",
                "price": "15",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/e-carte-cadeau/?Montant=50",
                "priceCurrency": "${currencyCode}",
                "price": "50",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/e-carte-cadeau/?Montant=75",
                "priceCurrency": "${currencyCode}",
                "price": "75",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/e-carte-cadeau/?Montant=100",
                "priceCurrency": "${currencyCode}",
                "price": "100",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/e-carte-cadeau/?Montant=150",
                "priceCurrency": "${currencyCode}",
                "price": "150",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              },
              {
                "@type": "Offer",
                "url": "https://${process.env.GATSBY_WEBSITE_URL}/${
              process.env.GATSBY_ACTIVE_ENV === "general"
                ? ""
                : process.env.GATSBY_ACTIVE_ENV
            }/e-carte-cadeau/?Montant=200",
                "priceCurrency": "${currencyCode}",
                "price": "200",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ) : (
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "${intl.formatMessage({ id: "seo_name_loop" })}",
            "image": "https://res.cloudinary.com/dcjf6omoh/image/upload/v1615916797/Percko_images_flux/Loop/Loop4_tdeoin.jpg",
            "description": "${intl.formatMessage({
              id: "seo_description_loop",
            })}",
            "brand": {
                "@type": "Brand",
                "name": "Percko"
              },
            "sku": "FR-LOOP",
            "offers": {
              "@type": "Offer",
              "url": "https://${process.env.GATSBY_WEBSITE_URL}/eur/shop/loop/",
              "priceCurrency": "${currencyCode}",
              "price": "${price_54_eur}",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1658"
            }
          }`}
          </script>
        ))}
    </Helmet>
  )
}
export default injectIntl(SeoComp)
SeoComp.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  twitter_card: PropTypes.string,
  jsonLd: PropTypes.oneOf([
    "wearable-technology",
    "lynefit",
    "lynehome",
    "lyneup",
    "loop",
    "matelas",
    "matelas-old",
    "matelas-new",
    "matelas-hybride",
    "matelas-mousse",
    "oreiller",
    "sommier-original",
    "sommier-coffre",
    "sommier-bois",
    "sommier-electrique",
    "surmatelas",
    "e-carte-cadeau",
    "oreillers-ergonomiques",
    "chaise-bureau",
    "chaise-gaming",
  ]),
}
SeoComp.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  twitter_card: "summary",
  jsonLd: null,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        # titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
